import React from "react";
import { Pagination, PaginationProps } from "antd";
import { PaginationLeftArrowIcon, PaginationRightArrowIcon } from "../../assets/icons";
import "./app-pagination.scss";
import "../../assets/sass/_pagination.scss";

interface ExtendedPaginationProps extends PaginationProps {
  scale?: number;
}

const itemRender: PaginationProps["itemRender"] = (_, type, originalElement) => {
  if (type === "prev") {
    return (
      <span className="pagination-icon">
        <PaginationLeftArrowIcon />
      </span>
    );
  }
  if (type === "next") {
    return (
      <span className="pagination-icon">
        <PaginationRightArrowIcon />
      </span>
    );
  }
  return originalElement;
};

export const AppPagination: React.FC<ExtendedPaginationProps> = ({ ...paginationProps }) => {
  return (
    <div className="app-pagination">
      <Pagination className="pagination" itemRender={itemRender} {...paginationProps} />
    </div>
  );
};
